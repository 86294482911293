<template>
  <v-container
    fill-height
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        v-if="canCloseWindow"
        sm="12"
      >
        <span><strong>Authorization Successful. You can close this window if it hasn't closed automatically.</strong></span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from '@/firebaseApp'
import { decryptData } from '@/functions'
import { appUserSignin, createAppUser, generateRandomPassword } from '@/functions/authentication'
import { getTokens, getUser } from '@/functions/snowflakeOauth'
import store from '@/store'
import { useRouter } from '@core/utils'
import serverConfig from '@serverConfig'
import { onMounted, ref } from '@vue/composition-api'
import { deleteDoc, doc } from 'firebase/firestore'

export default {
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    let accountId = null
    let snowflakeUsername = null
    let autoCreateAccount = null
    const canCloseWindow = ref(false)

    const { router } = useRouter()

    const getUserData = async () => {
      if (accountId && snowflakeUsername) {
        try {
          const userData = await getUser(accountId, snowflakeUsername)
          if (!userData) {
            return 'No User'
          }

          return userData
        } catch (err) {
          console.log('Error: ', err)

          return 'Failed'
        }
      }

      return 'Failed'
    }

    onMounted(async () => {
      store.commit('showLoadingInterface', true)
      localStorage.setItem('snowflakeCode', props.code)
      try {
        const accessToken = await getTokens()
        if (accessToken.startsWith('ver')) {
          // Successful Token Received
          accountId = localStorage.getItem('accountId')
          snowflakeUsername = localStorage.getItem('snowflakeUsername')
          autoCreateAccount = localStorage.getItem('autoCreateAccount')
          localStorage.setItem('oauthVerificationCheck', 'Success')
          const userData = await getUserData()

          // If User is only in Authorized State but Has no created Account
          if (userData.authorized) {
            // Create a Random Password - Required So Firebase Can Authenticate
            const randomPassword = await generateRandomPassword(20)

            // Remove Keys From Temp Profile Not Needed for New User
            const removeKeys = ['linkType', 'tempPassword', 'authorized', 'docId']
            removeKeys.forEach(key => delete userData[key])

            // Add User Data For Oauth Connection
            userData.snowflakeCredentials.oauthVerified = true
            userData.created = new Date(userData.created.seconds * 1000)
            const payload = {
              ...userData,
              source: 'oauth',
              password: randomPassword,
              accountId,
              email: userData.email,
              userData,
              type: 'newUser',
            }
            const createAppUserResponse = await createAppUser(payload)
            if (createAppUserResponse === 'Success') {
              await deleteDoc(
                doc(
                  db,
                  `users/accounts/${accountId}/waitingOnAccountCreation/waitingOnAccountCreation`,
                  userData.email,
                ),
              )
              router.push('/')
            }

            store.commit('showLoadingInterface', false)

            return
          }

          // If No User BUT autoCreateAccount is True
          if (userData === 'No User' || userData.authorized) {
            // Sending to Account Setup to Create User
            if (autoCreateAccount === true || autoCreateAccount === 'true') {
              store.commit('showLoadingInterface', false)
              router.push('/account-setup/create-user-from-oauth/')

              return
            }
            localStorage.setItem(
              'logoutMessage',
              `Sorry, your Snowflake Oauth account has not yet been setup in ${serverConfig.name}. Please contact your administrator.`,
            )
            router.push('/')

            return
          }

          // If user is not logged in... Log user in...
          if (!localStorage.getItem('loggedIn')) {
            const decryptedPassword = await decryptData(userData.password)
            await appUserSignin(userData.email, decryptedPassword.data)
            store.commit('showLoadingInterface', false)
            router.push('/')
          }
        } else {
          store.commit('showLoadingInterface', false)
          router.push('/')
        }
        if (localStorage.getItem('oauthConnectionTest')) {
          localStorage.removeItem('oauthConnectionTest')
          canCloseWindow.value = true
          window.close()
        }
      } catch (err) {
        console.log(err)
        router.push('/')
      }
    })

    return {
      canCloseWindow,
    }
  },
}
</script>
